import React from 'react'
import Header from '../components/Header/Header'
import Feedback from '../components/Feedback/Feedback'
import Footer from '../components/Footer/Footer';

function FeedbackPage() {
  return (
    <>
        <Header/>
        <Feedback/>
        <Footer/>
    </>
  );
};

export default FeedbackPage;