import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Header.module.css';
import alaniLogo from '../../img/alaniLogo.webp';
import Button from '../Button/Button';

function Header({ fixed }) {
  const navigate = useNavigate();

  return (
    <header className={styles.header} style={{position: `${fixed ? 'fixed' : ''}`}}>
        <img src={alaniLogo} alt="Alani Nu Logo" className={styles.logo} onClick={() => navigate('/')}/>
        <Button onClick={() => navigate('/points')}>Get Energized</Button>
    </header>
  );
};

export default Header;