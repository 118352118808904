import React from 'react';
import Header from '../components/Header/Header';
import Quiz from '../components/Quiz/Quiz';
import Footer from '../components/Footer/Footer';

function QuizPage() {
  return (
    <>
        <Header fixed/>
        <Quiz/>
        <Footer fixed/>
    </>
  );
};

export default QuizPage;