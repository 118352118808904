import React from 'react';
import Header from '../components/Header/Header';
import Can from '../components/Can/Can';
import Footer from '../components/Footer/Footer';

function MainPage() {
  return (
    <>
      <Header fixed/>
      <Can/>
      <Footer fixed/>
    </>
  );
};

export default MainPage;