import React, { useState, useEffect } from 'react';
import styles from './CanFiller.module.css';

function CanFiller({ percentage }) {
    // State to control the current height during animation
    const [currentHeight, setCurrentHeight] = useState(0);

    // Ensure the percentage is between 0 and 100
    const clampPercentage = Math.min(100, Math.max(0, percentage));

    // Function to animate the bar filling up
    useEffect(() => {
        let animationFrame;
        const animateHeight = () => {
        setCurrentHeight((prevHeight) => {
            if (prevHeight < clampPercentage) {
            animationFrame = requestAnimationFrame(animateHeight);
            return prevHeight + 1; // Increment by 1% each time
            } else {
            return clampPercentage;
            }
            });
        };

        // Start the animation
        animationFrame = requestAnimationFrame(animateHeight);

        // Cleanup animation frame on component unmount
        return () => cancelAnimationFrame(animationFrame);
    }, [clampPercentage]);

    return (
        <div className={styles.circleContainer}>
            {/* Circular container */}
            <div className={styles.circle}>
            {/* Dynamic bar with inline style for height */}
            <div
                className={styles.bar}
                style={{ 
                    height: `${currentHeight}%`,
                    color: (clampPercentage * 10) >= 150 ? "white" : "black"
                }}
            >{clampPercentage * 10}</div>
            </div>
        </div>
    );
};

export default CanFiller;