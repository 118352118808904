import React from 'react';
import styles from './Input.module.css';

function Input({ type = 'text', name, id, value, onChange, placeholder, children, onToggleVisibility, isPasswordVisible, ...rest }) {
    return (
        <div className={styles.inputWrapper}>
            <input 
                type={type} 
                name={name} 
                id={id} 
                value={value} 
                onChange={onChange}
                placeholder={placeholder} 
                {...rest}
                className={styles.inputField}
            />
            <label htmlFor={id} className={styles.placeholderText} id={`placeholder-${id}`}>
                <div className={styles.labelText}>{children}</div>
            </label>
            {onToggleVisibility && (
                <span className={styles.visibilityToggle} onClick={onToggleVisibility}>
                    <span className="material-symbols-outlined">
                        {isPasswordVisible ? 'visibility_off' : 'visibility'}
                    </span>
                </span>
            )}
        </div>
    );
}

export default Input;