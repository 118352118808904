import React, { useEffect, useState } from 'react';
import styles from './Can.module.css';
import alaniCan1 from '../../img/alaniCan1.webp';
import alaniCan2 from '../../img/alaniCan2.webp';
import alaniCan3 from '../../img/alaniCan3.webp';
import alaniCan4 from '../../img/alaniCan4.webp';
import alaniCan5 from '../../img/alaniCan5.webp';
import alaniCan6 from '../../img/alaniCan6.webp';
import alaniCan7 from '../../img/alaniCan7.webp';
import alaniCan8 from '../../img/alaniCan8.webp';
import alaniCan9 from '../../img/alaniCan9.webp';
import Fact from '../Fact/Fact';

const canImages = [
    alaniCan1,
    alaniCan2,
    alaniCan3,
    alaniCan4,
    alaniCan5,
    alaniCan6,
    alaniCan7,
    alaniCan8,
    alaniCan9,
]

function Can() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [canIndex, setCanIndex] = useState(0);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            setCanIndex((prevIndex) => (prevIndex + 1) % canImages.length);
        }, 5000);

        return () => {
            clearInterval(interval)
        }
    }, [canImages.length]);

  return (
    <div className={styles.canWrapper}>
        <div className={styles.canContainer}>
            <div style={{
                    position: "absolute",
                    top: "15%",
                    right: "23%"
                }}
            >
                <Fact
                    dropdownSide={windowWidth > 700 ? 'right' : 'left'}
                    backgroundColor='white'
                ><span style={{
                    fontWeight: "bold"
                }}>L-carnitine:</span> Helps the body turn fat into energy</Fact>
            </div>
            <div style={{
                    position: "absolute",
                    top: "35%",
                    left: "27%"
                }}
            >
                <Fact
                    dropdownSide={windowWidth > 700 ? 'left' : 'right'}
                    backgroundColor='white'
                ><span style={{
                    fontWeight: "bold"
                }}>L-theanine:</span> Helps ease anxiety, stress, and reduce insomnia</Fact>
            </div>
            <div style={{
                    position: "absolute",
                    top: "55%",
                    right: "23%"
                }}
            >
                <Fact
                    dropdownSide={windowWidth > 700 ? 'right' : 'left'}
                    backgroundColor='white'
                ><span style={{
                    fontWeight: "bold"
                }}>B vitamins:</span> Essential for the body, helping with a variety of functions including promoting brain health, converting food and fat to fuel for your body, and improving metabolism</Fact>
            </div>
            <div style={{
                    position: "absolute",
                    top: "75%",
                    left: "27%"
                }}
            >
                <Fact
                    dropdownSide={windowWidth > 700 ? 'left' : 'right'}
                    backgroundColor='white'
                ><span style={{
                    fontWeight: "bold"
                }}>Beta-alanine:</span> Aids in the production of carnosine, improving muscular endurance</Fact>
            </div>
            <img src={canImages[canIndex]} alt="Alani Nu Can" className={styles.can}/>
        </div>
    </div>
  );
};

export default Can;