import React from 'react';
import styles from './Form.module.css';

function Form({ children, ...rest }) {
  return (
    <div className={styles.formWrapper} {...rest}>
        <div className={styles.formCard}>
            {children}
        </div>
    </div>
  );
};

export default Form;