import React from 'react';

// Components
import Header from '../components/Header/Header';
import Points from '../components/Points/Points';
import Footer from '../components/Footer/Footer';

function PointsPage() {
  return (
    <>
        <Header/>
        <Points/>
        <Footer/>
    </>
  );
};

export default PointsPage;