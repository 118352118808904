import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, useParams } from 'react-router-dom';

// Pages
import MainPage from './pages/MainPage';
import QuizPage from './pages/QuizPage';
import FeedbackPage from './pages/FeedbackPage';
import PointsPage from './pages/PointsPage';
import Error404 from './pages/Error404';

// TODO: Add actual points support with session or local storage

function App() {
  return (
    <BrowserRouter>
        <Routes>
            <Route 
                path="/"
                element={<MainPage/>}
            />
            <Route 
                path='/quiz'
                element={<QuizPage/>}
            />
            <Route 
                path='/feedback'
                element={<FeedbackPage/>}
            />
            <Route 
                path='/points'
                element={<PointsPage/>}
            />
            <Route 
                path='/*'
                element={<Error404/>}
            />
        </Routes>
    </BrowserRouter>
  )
}

export default App