import React, { useState, useEffect, useRef } from 'react';
import styles from './Fact.module.css';

function Fact({ children, dropdownSide = 'right', backgroundColor = 'white', ...rest }) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const factRef = useRef(null);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    }

    const handleClickOutside = (e) => {
        if (factRef.current && !factRef.current.contains(e.target)) {
          setIsDropdownOpen(false);
        }
      };
  
      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        }
      }, []);

  return (
    <div className={styles.factWrapper} ref={factRef} {...rest}>
        <div 
            className={styles.factBox}
            style={{
                backgroundColor: backgroundColor
            }}
        >
            <span 
                className='material-symbols-rounded'
                style={{
                    cursor: "pointer",
                    color: "#66c9ba",
                    userSelect: "none"
                }}
                onClick={toggleDropdown}
            >info</span>
        </div>
        {/* Dropdown */}
        {isDropdownOpen && (
            <div className={`${styles.factDropdown} ${styles[dropdownSide]}`}>
                {children}
            </div>
        )}
    </div>
  );
};

export default Fact;