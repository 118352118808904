import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header/Header';
import Button from '../components/Button/Button';

function Error404() {
    const navigate = useNavigate();

  return (
    <>
        <Header/>
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100dvh",
            width: "100dvw",
            flexDirection: "column",
            gap: "1rem"
        }}>
            <h1>Error 404 - Page Not Found</h1>
            <Button onClick={() => navigate('/')}>Go Back</Button>
        </div>
    </>
  )
}

export default Error404;