import React, { useState } from 'react';
import styles from './Slider.module.css';

function Slider({ min = 0, max = 10, step = 1, defaultValue = 5, onChange, ...rest }) {
    const [value, setValue] = useState(defaultValue);

    const handleChange = (e) => {
        setValue(e.target.value);
        if (onChange) {
            onChange(e.target.value);
        }
    };

    return (
        <div className={styles.sliderWrapper} {...rest}>
            <input 
                type="range"
                min={min}
                max={max}
                step={step}
                value={value}
                onChange={handleChange}
                className={styles.slider}
            />
        </div>
    );
};

export default Slider;