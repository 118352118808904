import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Quiz.module.css';
import Form from '../Form/Form';
import Button from '../Button/Button';

function Quiz() {
    const [q1Answer, setQ1Answer] = useState(null);
    const [q2Answer, setQ2Answer] = useState(null);
    const [q3Answer, setQ3Answer] = useState(null);
    const [q4Answer, setQ4Answer] = useState(null);
    const [correctAnswers, setCorrectAnswers] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState(1);
    const navigate = useNavigate();

    const getCorrectAnswers = () => {
        let correct = 0;
        if (q1Answer === true) correct++;
        if (q2Answer === true) correct++;
        if (q3Answer === true) correct++;
        if (q4Answer === true) correct++;
        setCorrectAnswers(correct);
        return correct;
    }

  return (
    <Form>
        <h2>Quiz Your Knowledge</h2>
        <p>Take this fun and simple quiz to test your knowledge of Alani Nu's ingredients and earn points for fun rewards!</p>
        {currentQuestion === 1 && (
            <div className={styles.questionContainer}>
                <p 
                    onClick={() => navigate('/')} 
                    style={{
                        display: "flex", 
                        alignItems: "center", 
                        cursor: "pointer", 
                        color: "grey"
                    }}
                >
                    <span 
                        className='material-symbols-rounded' 
                        style={{fontSize: "18px"}}>
                            keyboard_arrow_left
                        </span>
                    Go Back
                </p>
                <strong>Question 1</strong>
                <p>L-carnitine helps your body turn fat into energy</p>
                <div className={styles.optionsContainer}>
                    <button 
                        className={`${styles.quizButton} ${q1Answer === true && styles.selected}`}
                        onClick={() => setQ1Answer(true)}
                    >True</button>
                    <button 
                        className={`${styles.quizButton} ${q1Answer === false && styles.selected}`}
                        onClick={() => setQ1Answer(false)}
                    >False</button>
                    <Button 
                        disabled={q1Answer === null}
                        onClick={() => setCurrentQuestion(2)}
                    >Next Question</Button>
                </div>
            </div>
        )}
        {currentQuestion === 2 && (
            <div className={styles.questionContainer}>
                <p 
                    onClick={() => setCurrentQuestion(1)} 
                    style={{
                        display: "flex", 
                        alignItems: "center", 
                        cursor: "pointer", 
                        color: "grey"
                    }}
                >
                    <span 
                        className='material-symbols-rounded' 
                        style={{fontSize: "18px"}}>
                            keyboard_arrow_left
                        </span>
                    Go Back
                </p>
                <strong>Question 2</strong>
                <p>L-theanine helps ease anxiety, stress, and reduce insomnia</p>
                <div className={styles.optionsContainer}>
                    <button 
                        className={`${styles.quizButton} ${q2Answer === true && styles.selected}`}
                        onClick={() => setQ2Answer(true)}
                    >True</button>
                    <button 
                        className={`${styles.quizButton} ${q2Answer === false && styles.selected}`}
                        onClick={() => setQ2Answer(false)}
                    >False</button>
                    <Button 
                        disabled={q2Answer === null}
                        onClick={() => setCurrentQuestion(3)}
                    >Next Question</Button>
                </div>
            </div>
        )}
        {currentQuestion === 3 && (
            <div className={styles.questionContainer}>
                <p 
                    onClick={() => setCurrentQuestion(2)} 
                    style={{
                        display: "flex", 
                        alignItems: "center", 
                        cursor: "pointer", 
                        color: "grey"
                    }}
                >
                    <span 
                        className='material-symbols-rounded' 
                        style={{fontSize: "18px"}}>
                            keyboard_arrow_left
                        </span>
                    Go Back
                </p>
                <strong>Question 3</strong>
                <p>A benefit of B vitamins is improving metabolism</p>
                <div className={styles.optionsContainer}>
                    <button 
                        className={`${styles.quizButton} ${q3Answer === true && styles.selected}`}
                        onClick={() => setQ3Answer(true)}
                    >True</button>
                    <button 
                        className={`${styles.quizButton} ${q3Answer === false && styles.selected}`}
                        onClick={() => setQ3Answer(false)}
                    >False</button>
                    <Button 
                        disabled={q3Answer === null}
                        onClick={() => setCurrentQuestion(4)}
                    >Next Question</Button>
                </div>
            </div>
        )}
        {currentQuestion === 4 && (
            <div className={styles.questionContainer}>
                <p 
                    onClick={() => setCurrentQuestion(3)} 
                    style={{
                        display: "flex", 
                        alignItems: "center", 
                        cursor: "pointer", 
                        color: "grey"
                    }}
                >
                    <span 
                        className='material-symbols-rounded' 
                        style={{fontSize: "18px"}}>
                            keyboard_arrow_left
                        </span>
                    Go Back
                </p>
                <strong>Question 4</strong>
                <p>Beta-alanine improves muscular endurance</p>
                <div className={styles.optionsContainer}>
                    <button 
                        className={`${styles.quizButton} ${q4Answer === true && styles.selected}`}
                        onClick={() => setQ4Answer(true)}
                    >True</button>
                    <button 
                        className={`${styles.quizButton} ${q4Answer === false && styles.selected}`}
                        onClick={() => setQ4Answer(false)}
                    >False</button>
                    <Button 
                        disabled={q4Answer === null}
                        onClick={() => {
                            setCurrentQuestion(null)
                            getCorrectAnswers();
                        }}
                    >Finish Quiz</Button>
                </div>
            </div>
        )}
        {currentQuestion === null && (
            <div className={styles.questionContainer}>
                <strong>Results</strong>
                {correctAnswers > 0 ? (
                    <div>You got <strong>{correctAnswers}/4 correct</strong>!</div>
                ) : (
                    <div>You got no answers correct :(</div>
                )}
                {correctAnswers > 0 ? (
                    <p><strong>+{correctAnswers * 50} Points!</strong></p>
                ) : (
                    <>
                        <p>No points this time! Try again?</p>
                        <Button onClick={() => window.location.reload()}>Try Again</Button>
                    </>
                )}
                <Button onClick={() => navigate('/')}>Go Back</Button>
            </div>
        )}
    </Form>
  );
};

export default Quiz;