import React, { useState } from 'react';
import { useNavigate } from'react-router-dom';
import styles from './Feedback.module.css';

// Components
import Form from '../Form/Form';
import Slider from '../Slider/Slider';
import Button from '../Button/Button';
import Input from '../Input/Input';

function Feedback() {
    const [q1, setQ1] = useState(5);
    const [q2, setQ2] = useState(5);
    const [q3, setQ3] = useState(5);
    const [q4, setQ4] = useState(5);
    const [q5, setQ5] = useState("");
    const [q6, setQ6] = useState("");
    const navigate = useNavigate();

    const handleSliderChange = (question, newValue) => {
        if (question === 1) setQ1(newValue);
        if (question === 2) setQ2(newValue);
        if (question === 3) setQ3(newValue);
        if (question === 4) setQ4(newValue);
        if (question === 5) setQ5(newValue);
    }
    
  return (
    <div className={styles.formContainer}>
        <Form style={{marginTop: "-2rem", marginBottom: "-2rem"}}>
            <h2>Give us some feedback!</h2>
            <p>Tell us what you think about Alani and earn points for fun rewards!</p>
            <p 
                onClick={() => navigate('/')} 
                style={{
                    display: "flex", 
                    alignItems: "center", 
                    cursor: "pointer", 
                    color: "grey"
                }}
            >
                <span 
                    className='material-symbols-rounded' 
                    style={{fontSize: "18px"}}
                >
                    keyboard_arrow_left
                </span>
                Go Back
            </p>
            <div className={styles.questionContainer}>
                <strong>How would you rate Alani Nu's communication?</strong>
                <Slider onChange={(value) => handleSliderChange(1, value)}/>
            </div>
            <div className={styles.questionContainer}>
                <strong>How much do you feel you know about Alani's ingredients?</strong>
                <Slider onChange={(value) => handleSliderChange(2, value)}/>
            </div>
            <div className={styles.questionContainer}>
                <strong>How much do you trust Alani?</strong>
                <Slider onChange={(value) => handleSliderChange(3, value)}/>
            </div>
            <div className={styles.questionContainer}>
                <strong>Do you find this website helpful for learning about Alani and our ingredients?</strong>
                <Slider onChange={(value) => handleSliderChange(4, value)}/>
            </div>
            <div className={styles.questionContainer} style={{display: "flex", flexDirection: "column", gap: ".5rem"}}>
                <strong>Briefly describe your experiences with Alani</strong>
                <Input style={{width: "100%"}} value={q5} onChange={(e) => setQ5(e.target.value)}></Input>
            </div>
            <div className={styles.questionContainer} style={{display: "flex", flexDirection: "column", gap: ".5rem"}}>
                <strong>How can we improve?</strong>
                <Input style={{width: "100%"}} value={q6} onChange={(e) => setQ6(e.target.value)}></Input>
            </div>
            <Button onClick={() => navigate('/')}>Submit Feedback</Button>
        </Form>
    </div>
  );
};

export default Feedback;