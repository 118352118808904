import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Points.module.css';

// Components
import Form from '../Form/Form';
import Button from '../Button/Button';
import CanFiller from '../CanFiller/CanFiller';

function Points() {
    const navigate = useNavigate();
    
  return (
    <div className={styles.formContainer}>
        <Form style={{marginTop: "-2rem", marginBottom: "-2rem"}}>
            <h2>Your Points</h2>
            <p>View the points you've earned from taking the quiz, giving us feedback, and your purchases; and what you can spend them on!</p>
            <p 
                onClick={() => navigate('/')} 
                style={{
                    display: "flex", 
                    alignItems: "center", 
                    cursor: "pointer", 
                    color: "grey"
                }}
            >
                <span 
                    className='material-symbols-rounded' 
                    style={{fontSize: "18px"}}>
                        keyboard_arrow_left
                    </span>
                Go Back
            </p>
            <CanFiller percentage={600/1000*100}/>
            <Button>Free Can! <span style={{fontSize: ".75rem"}}>600 points</span></Button>
            <Button>One entry to win a takeover of Alani's Social Medias <span style={{fontSize: ".75rem"}}>600 points</span></Button>
            <Button disabled>Free 4-pack of Alani <span style={{fontSize: ".75rem"}}>2,200 points</span></Button>
        </Form>
    </div>
  );
};

export default Points;