import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Footer.module.css';
import ig from '../../img/icons8-instagram.svg'
import tiktok from '../../img/icons8-tiktok (1).svg'
import Button from '../Button/Button';

function Footer({ fixed, ...rest }) {
  const navigate = useNavigate();

  return (
    <div className={styles.footer} style={{position: `${fixed ? 'fixed' : ''}`}}>
        <div className={styles.row}>
            <Button onClick={() => navigate('/quiz')}>Take The Quiz</Button>
            <Button onClick={() => navigate('/feedback')}>Give Feedback</Button>
        </div>
        <div className={styles.row}>
            <a href="https://www.instagram.com/alaninutrition" rel="noreferrer" target='_blank'><img src={ig} alt="" className={styles.logo}/></a>
            <a href="https://www.tiktok.com/@alaninutrition" rel="noreferrer" target='_blank'><img src={tiktok} alt="" className={styles.logo}/></a>
        </div>
    </div>
  );
};

export default Footer;